import { createSlice } from "@reduxjs/toolkit";

const audioSyncSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
  },
  reducers: {
    incremented: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log('te')
      state.value += 1;
    },
    decremented: (state) => {
      state.value -= 1;
    },
  },
});

export default audioSyncSlice;
export const { incremented, decremented } = audioSyncSlice.actions;
