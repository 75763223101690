import React from "react";
import Markdown from "markdown-to-jsx";
import Post from "../posts/Atelier Ryza.js";
import Callout from "./Callout.js";
import Paragraph from "./Paragraph.js";
import ReactPlayer from "react-player";
import { Provider } from "react-redux";

import "../css/App.css";
import ContentQuickie from "./ContentQuickie.js";

import audioSyncSlice, { incremented } from "../slices/audioSyncSlice.js";

import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: audioSyncSlice.reducer,
});

function Test() {
  return (
    <Provider store={store}>
      <div className="post-content-container">
        {/* <ReactPlayer
          controls={true}
          volume={0.2}
          onPlay={() => {
            store.dispatch(incremented());
          }}
          // ref={() =>}
          url={
            "https://dl.dropboxusercontent.com/scl/fi/1xrbec3y69yhsfekqsnjk/audio.wav?rlkey=q3a4eh5ozffrx5caamj71jqj9&dl=0"
          }
        /> */}

        <ContentQuickie name={Post.title} description={Post.short} />
        <Markdown
          className="post-content"
          options={{
            overrides: {
              Callout: {
                component: Callout,
              },
              p: {
                component: Paragraph,
              },
            },
          }}
        >
          {Post.md}
        </Markdown>
        <span>🤔</span>
      </div>
    </Provider>
  );
}

export default Test;
