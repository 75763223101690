const md = `<Callout color="#587d88ad">
***The Steam Summer Sale***. An annual event that arrives without fail each year, and each year I convince myself that I won't be spending a single penny on spontaneous blockbuster deals. However, and perhaps inevitably, I acquire an entirely new assortment of games to contribute to my ever-growing backlog. Typically, these games languish in my library, untouched and overlooked. Services like Humble Bundle and their monthly loot box of incoming Steam keys do little to alleviate this predicament.

This year was no different—a promise made to self, a promise broken. In the wake of this devastation to my wallet, I've decided to put these games to work. This time any game purchased during the sale - to which there are many - will have a comprehensive review written and posted to this website! No longer will my games languish in an imagined abyss! My library, teeming with unexplored treasures, shall no longer remain dormant!

The shackles that bind me to endless Counter Strike Global Offensive matches shall be made like water, frozen and shattered! Let this be a promise to self that shall not be broken.
</Callout>

Sometimes, we as people feel like trapped birds longing to spread our wings and soar. We yearn to taste the wild and the unknown like sailors craving the salty sea air. The mundane routine can feel like a prison, and the only way to break free is to seek new adventures and experiences, like treasure hunters on a quest.

This tale begins with one Ryza Stout, who, at this moment, has an intense craving for adventure. She resides on a secluded island with nothing but cows, wheat, and stringent rules. The same old trees, the same old faces. Such a dreary life could cause anyone to lose their mind; hence, Ryza resolves to set out on a voyage to quench her thirst for thrill. As long as she's back before dinner, what can possibly go wrong?

Thus, in tow with long-time friends Lent and Tao, our heroes strike out into lands unknown. This is how a teenage budding adventure should feel like. Heroes way out of their depth, striving for heroics without genuinely knowing the danger they throw themselves into.

Our characters feel trapped. They seek adventure because this is their only escape from the ordinary, and that is a trait I find entirely relatable.

For now, let's turn our attention toward combat instead because, for the second time in my two-time run as a game reviewer, combat revolves around a timeline.

In case you've no idea what I'm talking about, imagine that rather than having turns based on an arbitrary stat, each character and monster in combat has a cooldown period before they can interact. This cooldown is represented by characters making their way through the ever-present timeline.

It is fun to watch but it feels like it is just watching most of the time. Combat sometimes exudes the essence of an auto battler, where most of the experience feels more geared towards automation rather than strategy.

Limitations placed on Items and Action Points make building momentum against enemies tiresome and sluggish. Let's talk about that because Action Points and Items do make up the building blocks of combat here.

Think of Action Points as a momentum gauge during combat. At the start, AP (action points) kick off at level 1. Characters earn AP whenever they make a basic attack. Once AP hits its maximum, you get the chance to level up. This is well worth it, as it boosts the power of all your characters' skills and increases its max holding capacity.

It's a clever system of delayed gratification, but it does have its downside – precisely that delay. In the midst of combat, if you allow your teammates to use up AP for their skills, even the basic goal of reaching level two will seldom be achievable.

As a result, a significant portion of the time, even though the choice to alter your strategy was available, it consistently seemed inferior compared to the straightforward approach of preserving your resources for more substantial damage in the future. And it's not limited to just skills – numerous aspects of combat hinge on reaching higher action point levels. This often leads to the tedious spectacle of characters repeatedly resorting to basic attacks.

However, there's a twist when it comes to using Items – they don't consume action points. Instead, they rely on another valuable resource known as Core Energy.

Initially, you're granted 10 units of Core Energy to manage, and each item carries its own cost for usage. Once you deplete this energy pool, you have two options for restoring it: either sacrifice another item or return to your home base using fast travel. This process resets the Core Energy count and makes the sacrificed items usable again. While this setup is reasonable, during combat, it can feel like an unnecessary constraint. Rarely are there moments where deciding what to sacrifice poses a genuine challenge, making the restoration of Core Energy more of an annoyance than a gripping moment of contemplation.

The enemies themselves rarely posed much of a challenge in terms of strategy. Sure, I died more than my fair share of times here, but those defeats never felt like losses in winnable battles. When defeat did occur, it was swift and sudden, leaving little room for a genuine struggle.

You might wonder how that's possible, given surely there are healing opportunities to take advantage of. Well, due to the way Action Points work, you need all of your teammates working together to build them up to usable levels. As far as I know, there are no items that revive allies, which means if even one of them dies, the rate at which you regain AP for skill usage shrinks drastically for that encounter.

The stun system only exacerbates this problem. If a character is attacked enough times or with a sufficiently powerful attack, they become stunned. This immensely impacts how long it takes for them to make their way down the timeline. You can imagine how this might end poorly if the wrong character in a lineup suddenly has their impact removed.

There isn't any struggle in that, only a slow acceptance of defeat or, worse yet, an even slower victory. This means boss fights never felt like an achievement of combat. I either absolutely destroyed them or died a slow, agonizing death with what feels like few options to stop the bleeding.

If all other choices feel like a bad choice, then there isn't really a choice at all. Characters have few skills to use, so there are rarely moments of tense indecision. I realize now that when these moments did happen, it was because I was choosing between 2 lesser actions.

On the plus side, the game makes it extremely clear when an enemy is weak or resistant to something. Sure, It's not often you'll have exactly what you need, but this does help to mitigate frustration.

Action Orders provide a path toward team synergy moves and doings, so they come with an AP bonus. The quick action option, while costing 10 AP to use, comes in clutch with the added benefit of sometimes allowing the ability to trigger enhanced synergy moves against more powerful enemies.

Watching the timeline in later levels, as crazy as it sounds, IS engaging. Playing for the stun, playing for the knockback, praying that the enemy attacks your tank instead of- Argh! Why is it focusing on my medic!

What did she do to you?

Listen. Contrary to initial impressions, the experience isn't negative overall. I emphasize the drawbacks because the potential for greatness is evident and tantalizingly close. The nearness to perfection is both promising and frustrating.

If the experience were outright poor or simply agonizing to engage with, the situation would be different. Yet, that's not the case here. The outlook improves significantly, particularly towards the game's conclusion, thanks to better-equipped gear and enhanced items.

I just wish I could have savored more of that end-game excellence without having to endure what feels like a vastly inferior experience in comparison.

I don't critique it because I hate it. On the contrary, it's good. It's so good, and it's so close to being better. It's so close to being better than better, and that's what I find most disappointing of all.

Now, let's talk about Alchemy.

I know what your thinking, and no, I'm not gonna tell you all the negatives just to turn around and say it's good at the end.

Crafting in Atelier Ryza is like going through a resource-driven skill tree. Each node takes a certain item with a certain element to it. Each node also has an elemental requirement that, when met, unlocks a trait that stacks onto the item. Generally, higher-quality items will provide more points to a node.

That's a lot, so let's start with an example: Say I want to craft a Bomb for totally heroic purposes. The first ingredient required to navigate the crafting tree is a crimson ore, which happens to be a fire-type item. 4 points of the provided fire element points will unlock that node's trait, which, in this case, is "Small Fire Damage."

After furnishing that node, I gain access to 3 other nodes, all of which have their own resource requirements and traits awarded upon reaching certain assigned elemental goals.


Certain nodes open the door to totally new recipes, so a big chunk of your progress actually comes from making your well-established recipes even better and totally nailing them. Here's a cool trick: You can actually craft advanced recipes by kicking things off with their prerequisite recipe. If you begin a new recipe using one of these recipe change nodes, the freshly made item will keep the same level as the recipe you kicked things off with, which makes it even easier to further improve it with item rebuilding.

As you might guess, these systems get pretty intricate. We're talking about Alchemy levels versus item levels, rebuilding items, optimizing gem reduction, and beefing up weapons, gear, and even tools for gathering. RPGs are often divided into two phases: preparation for combat and combat.

If you like the preparation phase, you will LOVE Atelier Ryza.

When you struggling in combat, the solution isn't to go grind monsters for XP because while yes, you can grind and gain XP to grow in strength, true strength comes from simply crafting better gear.

The biggest damage comes not from having the best weapon but from having the right item in the right place. This further substantiates my previous point of combat being either too easy or ridiculously unforgiving.

When it's easy, you don't feel the need to do much crafting, but then you run into that one reskinned dragon boss fight and get absolutely demolished. You go home, craft an armory of heroic nuclear lightning, and then suddenly, that boss isn't so challenging anymore.

Given a system like this, it's understandable that in a world driven by materials and crafting, material gathering becomes king. After all, to craft the best items, weapons, and gear, you need the best materials.

This results in a loop of exploration done to find new materials, craft new tools, items, and equipment to explore further lands to find new materials, to craft better tools...

Most gather nodes can be exhausted using multiple different tools, leading to entirely different crafting materials. This means that when you find a new location, there's a new phase of discovering what gathering tool rewards what material at various points of interest, and of course, all enemies drop materials.

It's fun, interesting, and all too satisfying to find a new item and ponder what it could be used for.

The downside is that there are a lot of items, and rather than making it easier to find some of these items again, Atelier Ryza cheats by enabling a system of grinding for past items, all without leaving the comfort of your base. It's a system that feels entirely uninspired and encourages a lack of discovery in the interest of furthering progression.

There are shops in this game, but their primary purpose is to purchase new recipes to craft and discover new and better equipment. While they do sell materials, it's often in low numbers and never quite what you need.

A journaling system that documents items and where you found them would've been so nice to see here. Quality of life improvements like this is one of the main things I'll look forward to in this series's second outing.

Speaking of forwards, our characters do a lot of that, don't they? Forwards, I mean.

Yeah, that wasn't the best transition, but it's still leagues better than how the story of Atelier Ryza does it, so let's talk about that.

Just like I mentioned earlier, the adventure kicks off right from the get-go. A bunch of young, eager heroes head out for an adventure, and as you can guess, they get a lot more than they bargained for.

What's cool about the storytelling here is that nothing comes out of thin air, and nothing just disappears for no reason. Every plot point gets its due and finds a satisfying wrap-up.

Even better, the characters in Atelier Ryza are pretty inquisitive about the world they're in, so I, as the player, didn't often feel like I was missing answers. Take, for example, Ryza learning how to make gems for alchemy. Her first question? "If I can make jewelry, why not sell it and get rich?"

In most games, there's always a resource that looks like it should be valuable but never actually pads the player's in-game wallet, so the question didn't even cross my mind. But the answer the game provided was satisfying and world-grounding, and moments like this happen all throughout.

Every time something seemed off or weird, the ongoing story managed to smooth it out. Even in the later parts of the game, where things are more about the spectacle, nothing feels pointless.

The build-up from "Hey, we're going on an adventure!" to "Alright, time to save the world, wish us luck" happens gradually, and the change in our characters' attitudes is believable and worth cheering for. I really wanted them to succeed, and when they did, I felt better for it.

But it's not all sunshine and rainbows. Atelier Ryza has this odd habit of making you wait between quests. You'll finish a task, and then the next thing is "wait 3 days."

I imagine it's to encourage working on Alchemy levels, but there are quests specifically meant for that, so I'm not entirely sure what's up with that.

More to that point, the story often asks you to fast-travel to a cutscene-triggering location so our heroes can talk and provide some exposition. 

It's not bad, but then the next quest will do the exact same thing. This might happen multiple times before finally resuming with adventuring heroics in monster-infested lands.

Sure, you can adventure whenever, but that's mainly for gathering items, not for moving the story ahead.

Now, a counter to this might be, "Just don't fast-travel. Walk to the objective." But wandering around areas with not much to collect gets dull. The towns in the game feel sort of generic, like the starting area of an old MMO. They give you multiple ways to skip around, so I mainly walked only when I had no other choice.

Thankfully, during these slow moments, the game's extensive library of soundtracks kept boredom at bay.

Each area has its own soundtrack, and since you're exploring a variety of places, there's always something new to listen to. It's a nice touch that kept me engaged from start to finish.

Speaking of the end, I'm running low on things to cover. Maybe it's time to wrap up this review.

While it's totally fair to say that combat ramps up as our heroes grow stronger in the story – starting slow and clunky, then becoming lightning-fast with alchemy enhancements – the problem lies in the limited comeback potential. Victories often feel like an all-or-nothing deal, lacking that satisfying feeling of triumph over a challenging situation.

The alchemy systems make gathering items satisfying, but the lack of quality-of-life features coupled with the scarcity of materials needed for progress can turn it into a chore rather than an enjoyable activity.

The story remains satisfying from beginning to end, even if it leans a bit on the lighter side. The characters are endearing, and their development is both believable and worth cheering for. However, the game's reluctance to delve deeper into mature and serious conflicts makes even the most significant moments in Atelier Ryza feel somewhat lacking in impact.

All in all, I genuinely like this game, and now that you know what to expect, I hope that one day you might enjoy it, too. I, for one, am already looking forward to playing its sequel, which I also grabbed during this year's Steam Summer Sale.

More on that next time? Perhaps?
`;

export default {
  md,
  title: "Atelier Ryza: Ever Darkness & the Secret Hideout",
  short: `Filled with lovable characters, grounded story telling, and a rewarding crafting loop It's hard to dislike whats shown here.`,
  words: `0.000	5.520	The
  5.520	6.000	Steam
  6.000	6.320	Summer
  6.320	6.760	Sale.
  6.760	7.560	An
  7.560	7.960	annual
  7.960	8.000	event
  8.000	8.080	that
  8.080	8.680	arrives
  8.680	8.960	without
  8.960	9.320	fail
  9.320	9.440	each
  9.440	9.880	year,
  9.880	10.240	and
  10.240	10.520	each
  10.520	10.840	year
  10.840	11.080	I
  11.080	11.360	convince
  11.360	11.760	myself
  11.760	11.760	that
  11.760	11.800	I
  11.800	12.120	won't
  12.120	12.120	be
  12.120	12.360	spending
  12.360	12.720	a
  12.720	13.160	single
  13.160	13.440	penny
  13.440	13.480	on
  13.480	14.200	spontaneous
  14.200	14.760	blockbuster
  14.760	16.160	deals.
  16.160	16.800	However,
  16.800	16.880	and
  16.880	17.320	perhaps
  17.320	17.960	inevitably,
  17.960	18.200	I
  18.200	18.680	acquire
  18.680	18.920	an
  18.920	19.400	entirely
  19.400	19.480	new
  19.480	20.000	assortment
  20.000	20.040	of
  20.040	20.440	games
  20.440	20.800	to
  20.800	21.560	contribute
  21.560	21.840	to
  21.840	22.080	my
  22.080	23.080	ever
  23.080	23.240	growing
  23.240	23.520	backlog.
  23.520	24.120	Typically,
  24.120	24.360	these
  24.360	25.080	games
  25.080	25.720	languish
  25.720	25.840	in
  25.840	26.280	my
  26.280	26.440	library,
  26.440	26.840	untouched
  26.840	27.000	and
  27.000	27.880	overlooked.
  27.880	28.320	Services
  28.320	28.480	like
  28.480	28.760	Humble
  28.760	29.000	Bundle
  29.000	29.160	and
  29.160	29.320	their
  29.320	29.520	monthly
  29.520	29.880	loot
  29.880	30.200	box
  30.200	30.240	of
  30.240	30.600	incoming
  30.600	30.920	Steam
  30.920	31.200	keys
  31.200	31.200	do
  31.200	31.200	little
  31.200	31.720	to
  31.720	32.120	alleviate
  32.120	32.320	this
  32.320	32.640	predicament.
  32.640	32.840	Well,
  32.840	34.720	this
  34.720	34.840	year
  34.840	35.520	was
  35.520	35.640	no
  35.640	36.360	different
  36.360	36.440	a
  36.440	37.120	promise
  37.120	37.600	made
  37.600	37.680	to
  37.680	37.840	self,
  37.840	37.920	A
  37.920	38.280	promise
  38.280	38.640	broken.
  38.640	38.640	In
  38.640	38.640	the
  38.640	38.720	wake
  38.720	38.720	of
  38.720	40.240	this
  40.240	40.760	devastation
  40.760	40.840	to
  40.840	41.200	my
  41.200	41.240	wallet,
  41.240	41.560	I've
  41.560	42.080	decided
  42.080	42.200	to
  42.200	42.280	put
  42.280	43.040	these
  43.040	43.280	games
  43.280	43.680	to
  43.680	43.880	work.
  43.880	44.240	This
  44.240	44.520	time
  44.520	44.800	any
  44.800	45.000	game
  45.000	45.680	purchased
  45.680	45.960	during
  45.960	46.000	the
  46.000	46.160	sale
  46.160	46.160	-
  46.160	46.200	to
  46.200	46.920	which
  46.920	47.200	there
  47.200	47.240	are
  47.240	47.480	many
  47.480	47.680	-
  47.680	48.200	will
  48.200	48.400	have
  48.400	48.680	a
  48.680	49.280	comprehensive
  49.280	49.600	review
  49.600	49.880	written
  49.880	50.360	and
  50.360	50.880	posted
  50.880	50.880	to
  50.880	51.680	this
  51.680	51.680	website!
  51.680	52.080	No
  52.080	52.320	longer
  52.320	52.400	will
  52.400	52.680	my
  52.680	53.040	games
  53.040	53.440	languish
  53.440	53.440	in
  53.440	53.600	an
  53.600	54.040	imagined
  54.040	54.480	abyss!
  54.480	55.000	My
  55.000	55.800	library,
  55.800	56.120	teeming
  56.120	56.320	with
  56.320	56.880	unexplored
  56.880	57.520	treasures,
  57.520	57.720	shall
  57.720	57.760	no
  57.760	58.120	longer
  58.120	58.480	remain
  58.480	58.800	dormant!
  58.800	58.800	The
  58.800	58.800	shackles
  58.800	58.800	that
  58.800	58.800	bind
  58.800	58.960	me
  58.960	59.640	to
  59.640	60.320	endless
  60.320	61.080	Counter
  61.080	61.680	Strike
  61.680	61.880	Global
  61.880	62.360	Offensive
  62.360	62.720	matches
  62.720	63.040	shall
  63.040	63.240	be
  63.240	63.440	made
  63.440	63.440	like
  63.440	63.840	water,
  63.840	64.800	frozen
  64.800	65.000	and
  65.000	65.440	shattered!
  65.440	65.560	Let
  65.560	65.800	this
  65.800	66.240	be
  66.240	66.440	a
  66.440	66.600	promise
  66.600	67.000	to
  67.000	67.560	self
  67.560	67.640	that
  67.640	67.960	shall
  67.960	68.200	not
  68.200	68.360	be
  68.360	71.640	broken.`,
};
