import React from "react";
import { useSelector, useDispatch } from "react-redux";

export default function Paragraph(props) {
  const count = useSelector((state) => state.value);

  return (
    <div>
      {props.children}
    </div>
  );
}
